/**
 *PROJECT start-template-v3
 * Created by WebStorm.
 * Author Khaliq ALI<hello@kgeek.me>)
 * Date 30/12/2021
 * TIME 18:03
 **/
import axios from 'axios'
import apiRouter from '../router/api-routes'

const http = axios.create({
  baseURL: apiRouter.baseURL
})

http.interceptors.request.use(function (request) {
  if (!request.baseURL.indexOf(apiRouter.baseURL)) {
    console.log('owner site')
  } else {
    console.log('other site')
  }
  return request
}, function (error) {
  return error
})

http.interceptors.response.use(function (response) {
  return response.data
}, function (error) {
  return Promise.reject(error.response ||
        { response: { status: error.statusCode, message: error.message } })
})

export default http
